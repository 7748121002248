import { observer } from 'mobx-react';
import { Button, Stack, Typography } from '@mui/material';

const Home = observer(() => {

    const openSubscribeForm = () => {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSc_68chtsXIwJ41OA8q-9aMhc3F35AECrFWrfshEJWbE4HMgw/viewform?usp=sf_link";
        return null;
    }

    return (
        <>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            fontWeight: '300',
            marginBottom: '20px',
            fontFamily: 'Roboto Mono Variable, monospace',
            fontSize: {
              xs: '2rem',  // Font size for small screens
              sm: '3rem',  // Font size for medium screens
              md: '4rem',  // Default font size for larger screens
            },
          }}
        >
          Healthcare Efficiency, Redefined.
        </Typography>
  
        <Typography
          variant="h6"
          color="white"
          sx={{
            fontWeight: '100',
            maxWidth: '90vw', // 90% of viewport width to make it flexible
            marginBottom: '40px',
            fontFamily: 'Roboto Mono Variable, monospace',
            fontSize: {
              xs: '1rem',  // Smaller font size on smaller screens
              sm: '1.2rem', // Medium screen
              md: '1.5rem', // Larger screens
            },
          }}
        >
          Imagine your team always having the right information at their fingertips—no delays, no confusion. It's coming soon.
        </Typography>
  
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flexWrap: 'wrap',  // Allow buttons to stack on smaller screens
            justifyContent: 'center',
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={{
              fontFamily: 'Roboto Mono Variable, monospace',
              padding: {
                xs: '10px 20px', // Adjust padding for smaller screens
                sm: '12px 24px', // Medium screens
              },
              fontSize: {
                xs: '0.9rem',  // Smaller button text on small screens
                sm: '1rem',  // Default button text on medium/large screens
              },
            }}
            onClick={() => openSubscribeForm()}
          >
            Stay Updated
          </Button>
  
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              fontFamily: 'Roboto Mono Variable, monospace',
              padding: {
                xs: '10px 20px',
                sm: '12px 24px',
              },
              fontSize: {
                xs: '0.9rem',
                sm: '1rem',
              },
            }}
          >
            Learn More
          </Button>
        </Stack>
        </>
    );
});

export default Home;