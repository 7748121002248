import { observer } from 'mobx-react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FourOhFour = observer(() => {
    const navigate = useNavigate();

    return (
        <>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            fontWeight: '300',
            marginBottom: '20px',
            fontFamily: 'Roboto Mono Variable, monospace',
            fontSize: {
              xs: '2rem',  // Font size for small screens
              sm: '3rem',  // Font size for medium screens
              md: '4rem',  // Default font size for larger screens
            },
          }}
        >
            Page Not Found!
        </Typography>
  
        <Typography
          variant="h6"
          color="white"
          sx={{
            fontWeight: '100',
            maxWidth: '90vw', // 90% of viewport width to make it flexible
            marginBottom: '40px',
            fontFamily: 'Roboto Mono Variable, monospace',
            fontSize: {
              xs: '1rem',  // Smaller font size on smaller screens
              sm: '1.2rem', // Medium screen
              md: '1.5rem', // Larger screens
            },
          }}
        >
                Oops, the page you are looking for does not exist.  
        </Typography>
  
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flexWrap: 'wrap',  // Allow buttons to stack on smaller screens
            justifyContent: 'center',
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={{
              fontFamily: 'Roboto Mono Variable, monospace',
              padding: {
                xs: '10px 20px', // Adjust padding for smaller screens
                sm: '12px 24px', // Medium screens
              },
              fontSize: {
                xs: '0.9rem',  // Smaller button text on small screens
                sm: '1rem',  // Default button text on medium/large screens
              },
            }}
            onClick={() => navigate('/')}
          >
            Return to homepage
          </Button>
        </Stack>
        </>
    );
});

export default FourOhFour;