import { Theme, createTheme } from '@mui/material';

export const myTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#1A535C",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#FF6B6B",
      contrastText: "#FFFFFF"
    }
  }
});

