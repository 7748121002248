import { AppBar, Box, Tab, Tabs, Toolbar, useTheme } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; 
// import Survey from './views/Survey';
import Home from './views/Home';
import { OpenInNew } from '@mui/icons-material';
import FourOhFour from './views/FourOhFour';

function App() {
    const theme = useTheme();  // Access Material-UI theme for breakpoints
    
    const openSurveyForm = () => {
      window.location.href = "https://forms.gle/zcNSVRJSmpBxt7bY9";
      return null;
  }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Navigation Bar */}
      <AppBar position="static" sx={{ backgroundColor: '#1A535C', color: 'white' }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <Tabs textColor="inherit" indicatorColor="secondary">
            <Tab
              label="Survey"
              icon={<OpenInNew />}
              iconPosition='end'
              sx={{
                fontFamily: 'Roboto Mono Variable, monospace'
              }}
              onClick={() => openSurveyForm()}
              />
          </Tabs>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box
        sx={{
          backgroundColor: '#1A535C',
          flexGrow: 1,  // Ensures this section takes the remaining space
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: '0 20px',
        }}
      >
        <Router>
          <Routes>
            <Route path='' element={<Home />} />
            <Route path='/' element={<Home />} />
            <Route path='*' element={<FourOhFour />} />
          </Routes>
        </Router>

      </Box>
      </Box>

    );
  }
    
export default App;
